<template>
  <div>
   <header-style title="Customer"/>
   <div class="user-list-table-customer mt-4 mx-2">
    <b-card class="card-filter">
    <div class="d-flex justify-content-between ">
      <div>
      <input-form  v-model="textSearch" placeholder="search" class="btn-search-customer mt-3"></input-form>
    </div>
      <div class="pt-3">
        <!-- <b-button class="btn-filter">  <i class="fas fa-filter"></i>Filter</b-button> -->
        <b-dropdown size="" variant="transparent3" right text="right align" toggle-class="text-decoration-none" no-caret
          class="p-0" id="dropdown-header-1">
          <template #button-content class="">
            <i class="fas fa-filter"></i> Filter
          </template>
          <div class="" style="width:300px">
            <h6 style="color:#5D636F;font-size: 14px;font-weight: 600; ">Filters</h6>
            <div class="mt-3">
              <label for="range-1" style="font-size:14px;font-weight:600;color:#161A22 ">Transaction Amount (SAR)</label>
              <MultiRangeSlider
                baseClassName="multi-range-slider-bar-only"
                :minValue="oBarMinValue"
                :maxValue="oBarMaxValue"
                :max="200"
                :min="0"
                :step="0.1"
                :rangeMargin="0"
                @input="update_oBarValues"
                class="range-slider"
              />
              <div
                style="display: flex; justify-content: space-between; text-align: center"
              >
                <span
                  style="
                    display: inline-block;
                    padding: 10px;
                    border: solid 1px #D3D6DE;
                    border-radius: 5px;
                    width: 106.5px;
                    height: 44px;
                    margin: 3px 5px;
                    color: #161A22;
                    font-size: 14px;
                    font-weight: 600;
                  "
                  >{{ oBarMinValue }}</span
                >
                <span class="pt-2" style="font-size:18px;font-weight: 600;color: #808693;">To</span>
                <span
                  style="
                    display: inline-block;
                    padding: 10px;
                    border: solid 1px #D3D6DE;
                    border-radius: 5px;
                    width: 106.5px;
                    height: 44px;
                    margin: 3px 5px;
                    color: #161A22;
                    font-size: 14px;
                    font-weight: 600;
                  "
                  >{{ oBarMaxValue }}</span
                >
              </div>
        </div>
        <div>
          <label for="range-1" style="font-size:14px;font-weight:600;color:#161A22 " class="mt-3">Transaction Count (SAR)</label>
          <b-form-input v-model="number" placeholder="0.00" style="width:104px;height:44px; margin:0px 3px"></b-form-input>

        </div>
        <div>
          <label for="range-1" style="font-size:14px;font-weight:600;color:#161A22 " class="mt-3">Project Count</label>
          <b-form-input v-model="number" placeholder="0.00" style="width:104px;height:44px; margin:0px 3px"></b-form-input>

        </div>
        <div align="end" class="mt-2">
          <b-button class="btn-cancel"  aria-describedby="dropdown-header-1">Cancel</b-button>
          <b-button variant="blue">Apply Filter</b-button>

        </div>
        </div>
        </b-dropdown>
        <b-button variant="blue" class="mx-1"> <i class="fas fa-arrow-circle-down"></i>Export</b-button>
      </div>
    </div>

    </b-card>
   <div>
    <main-table
        class=""
        :fields="fields"
        :items="items"
        createBtnLabel="add User"
        createRouteName=""
        excelName="users"
      />
      </div>
   <div class="py-3 px-4 d-flex justify-content-between">
        <div class="pages">
      <p>Row per page</p>
      <div class="dropdown">
  <b-dropdown split text='20' class="" variant="transparent">
    <b-dropdown-item href="#">1</b-dropdown-item>
    <b-dropdown-item href="#">2</b-dropdown-item>
    <b-dropdown-item href="#">3</b-dropdown-item>
  </b-dropdown>
</div>
    </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-number
        align="right"
        prev-text="Prev"
      next-text="Next"
      ></b-pagination>

    </div>
  </div>
  </div>
</template>
<script>
import MultiRangeSlider from 'multi-range-slider-vue'
import 'multi-range-slider-vue/MultiRangeSliderBlack.css'
import 'multi-range-slider-vue/MultiRangeSliderBarOnly.css'
import HeaderStyle from '../../components/core/partials/HeaderStyle/HeaderStyle.vue'
export default {
  name: 'merchant',
  components: { HeaderStyle, MultiRangeSlider },
  data () {
    return {
      oBarMinValue: 10,
      oBarMaxValue: 90,
      // mainProps: { blank: true, blankColor: '#777', width: 120, height: 120, class: 'm1' },
      fields: [
        { key: 'Name', label: 'name', type: 'text' },
        { key: 'Email', label: 'Email', type: 'email' },
        { key: 'phonenumber', label: 'Phone Number' },
        { key: 'totaltrxcount', label: 'Total TRX Count' },
        { key: 'totaltrxamount', label: 'Total TRX Amount' },
        { key: 'projectcount', label: 'Project Count', type: 'number' }
      ],
      items: [
        { id: '20', Name: 'Mohamed Gamal', Email: 'Mohamedibrahim@email.com', phonenumber: '+996-11123-4567', totaltrxcount: '10', totaltrxamount: '10,450 SAR', projectcount: '1' },
        { id: '21', Name: 'Ahmed Mohamed', Email: 'Ahmedmo@email.com', phonenumber: '+996-11123-4567', totaltrxcount: '12', totaltrxamount: '100,000 SAR', projectcount: '3' },
        { id: '22', Name: 'ibrahim Farouk', Email: 'Ahmedmo@email.com', phonenumber: '+996-11123-4567', totaltrxcount: '5', totaltrxamount: '4,500 SAR', projectcount: '5' },
        { id: '20', Name: 'Mohamed Gamal', Email: 'Mohamedibrahim@email.com', phonenumber: '+996-11123-4567', totaltrxcount: '10', totaltrxamount: '4,500 SAR', projectcount: '2' },
        { id: '21', Name: 'Ahmed Mohamed', Email: 'Ahmedmo@email.com', phonenumber: '+996-11123-4567', totaltrxcount: '12', totaltrxamount: '4,500 SAR', projectcount: '7' },
        { id: '22', Name: 'ibrahim Farouk', Email: 'Ahmedmo@email.com', phonenumber: '+996-11123-4567', totaltrxcount: '5', totaltrxamount: '4,500 SAR', projectcount: '9' }
      ],
      rows: 100,
      perPage: 1,
      currentPage: 1
    }
  },
  methods: {
    update_oBarValues (e) {
      this.oBarMinValue = e.minValue
      this.oBarMaxValue = e.maxValue
    }
  }
}
</script>
<style>
.btn-cancel{
  background-color: transparent !important;
  color: black;
  border: none;
}
.btn-cancel:hover{
  background-color: transparent !important;
  color: black;
  border: none;
}

.user-list-table-customer{
  background-color: white;
  /* box-shadow: 0 4px 12px 2px rgba(102, 102, 102, 0.16); */
  padding: 0px;
  border-radius: 8px;
  margin-inline-end: 20px;
  margin-inline-start: 10px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.MultiRangeSliderContainer {
  margin: auto;
  width: 800px;
}
.multi-range-slider-bar-only .bar-inner {
    background-color: #0A7EBA;
    border: solid 1px #E7F2F8;
    box-shadow: none;
    height:6px;
}
.multi-range-slider-bar-only .bar-left {
    width: 25%;
    height: 4px;
     background-color: #E7F2F8;
     border-radius: 10px 0px 0px 10px;
    box-shadow: none;
    padding: 2px 0px;
}
.multi-range-slider-bar-only .bar-right {
    width: 25%;
    height: 4px;
     background-color: #E7F2F8;
     border-radius: 10px 0px 0px 10px;
    box-shadow: none;
    padding: 2px 0px;

}
.multi-range-slider-bar-only .thumb::before {
    content: '';
    background-color: #0A7EBA;
    position: absolute;
    width: 14px;
    height: 14px;
    border: none;
    box-shadow: none;
    border-radius: 50%;
    z-index: 1;
    margin: -4px;
    cursor: pointer;
}
</style>
